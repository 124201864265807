import { Link } from "gatsby"
import React from "react"
import LazyLoad from "react-lazyload"
import CarouselComponent from "./carousel-component"
import SVGIcon from "./SVGIcon"
function LatestBlogItems(props) {
  return (
    <>
      <div className="grid-container no-caros">
        {props.items.map((post, i) => {
          const { title, coverImage, tags, slug } = post
          return (
            <div className="grid-lg-3">
              <div
                data-sal="slide-up"
                data-sal-delay={(i + 1) * 200}
                data-sal-easing="ease-out-bounce"
                data-sal-duration="700"
              >
                <Link to={`/blog/${slug}`}>
                  <div className="wrap">
                    <div className="cover">
                      <LazyLoad once offset={100}>
                        <img
                          src={coverImage?.url}
                          alt={title}
                          height="100"
                          width="100"
                        />
                      </LazyLoad>
                    </div>
                    <div className="content position-relative">
                      <div>
                        {tags.map((tag, ti) => {
                          return (
                            <div className="tag p14" key={ti}>
                              <Link
                                to={`/blog/categories/${tag.toLowerCase()}`}
                              >
                                <p>{tag}</p>{" "}
                              </Link>
                              <p
                                className="line"
                                style={{
                                  display: ti === tags.length - 1 && "none",
                                }}
                              >
                                {" "}
                                |{" "}
                              </p>
                            </div>
                          )
                        })}
                      </div>
                      <span className="alt">
                        <h4 title={title}> {title} </h4>
                      </span>

                      <span className="a14">
                        <Link
                          to={`/blog/${slug}`}
                          className="position-absolute d-flex align-items-center link"
                        >
                          read more
                          <SVGIcon name="arrowright" className="arrow-right" />
                        </Link>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )
        })}
      </div>

      <div className="grid-container caros">
        <CarouselComponent items={1} swipeable>
          {props.items.map((post, i) => {
            const { title, coverImage, tags, slug } = post
            return (
              <div className="caros-item">
                <Link to={`/blog/${slug}`}>
                  <div className="wrap">
                    <div className="cover">
                      <LazyLoad once offset={100}>
                        <img
                          src={coverImage?.url}
                          alt={title}
                          height="100"
                          width="100"
                        />
                      </LazyLoad>
                    </div>
                    <div className="content position-relative">
                      <div>
                        {tags.map((tag, tin) => {
                          return (
                            <div className="tag p14" key={tin}>
                              <p>{tag}</p>
                              <p
                                className="line"
                                style={{
                                  display: tin === tags.length - 1 && "none",
                                }}
                              >
                                {" "}
                                |{" "}
                              </p>
                            </div>
                          )
                        })}
                      </div>
                      <span className="alt">
                        <h4 title={title}> {title} </h4>
                      </span>

                      <span className="a14">
                        <Link
                          to={`/blog/${slug}`}
                          className="position-absolute"
                        >
                          read more
                          <LazyLoad once offset={100}>
                            <img
                              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/2j9zFnn3SWGuSdxuUPVA"
                              alt="arrow"
                              height="7"
                              width="4"
                              className="arrow position-relative"
                            />
                          </LazyLoad>
                        </Link>
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </CarouselComponent>
      </div>
    </>
  )
}

export default LatestBlogItems
